import '../styles/global.scss'
import Head from "next/head";
/*import ReactGA from 'react-ga';*/
import {createContext, useContext, useEffect, useState} from "react";
import {useRouter} from "next/router";
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import Script from 'next/script'
import * as gtag from "../src/gtag";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import Cookies from 'js-cookie';
import {SmoothScrollProvider} from "../components/SmoothScroll/SmoothScroll";
import {AppProps} from "next/app";

config.autoAddCss = false;

const axios = require('axios');

axios.defaults.baseURL = process.env.NEXT_PUBLIC_BACKEND_URL || 'https://barbaros.satelstories.com';


const DataKeeperContext = createContext<{
  routerChangeCount: number,
  getV: () => number,
  setV: (x: number) => void
}>({routerChangeCount: 0, getV: () => 0, setV: (x) => {}})

export const DataKeeperProvider = (props: any) => {

  let [v, setV] = useState(0)
  const value = {
    routerChangeCount: 0,
    getV: () => {
      return v
    },
    setV: (x: number) => {
      setV(x)
    }
  }

  return (
    <DataKeeperContext.Provider value={value}>
      {props.children}
    </DataKeeperContext.Provider>
  )
}

export const useDataKeeper = () => {
  return useContext(DataKeeperContext)
}

// let TRACKING_ID = `G-M4XEC7X740`;
let TRACKING_ID = `GTM-5939NK8`;

if (!process.env.NEXT_PUBLIC_ANALYTICS_DISABLED) {
  /*ReactGA.initialize(TRACKING_ID);*/
}

export default function MyApp({Component, pageProps}: AppProps) {
  const router = useRouter();


  useEffect(() => {
    gtag.pageview(window.location.pathname + window.location.search);
    if (window.location.search.indexOf('utm_') != -1) {
      window.originalParams = window.location.search
      let newParams = window.location.search.replace(/(\&|\?)utm([^&]+)/g, "").replace(/^&/, "?");
      if (newParams.length > 0 && !newParams.startsWith("?")) {
        newParams = "?" + newParams;
      }
      router.replace(window.location.pathname + newParams, undefined, {shallow: true})
    }
    if (window.location.search.indexOf('seq') != -1) {
      let parsed = /seq=([^&]+)/g.exec(window.location.search)![1];
      setSeqVal(parsed)
      setSeq(parsed)
    }
  }, []);

  const value = useDataKeeper()

  const handleRouteChange = (url: string, {shallow} : {shallow: boolean}) => {
    value.routerChangeCount = value.routerChangeCount + 1;
    value.setV(value.getV() + 1)
    window.redirectsCounter = (window.redirectsCounter || 0) + 1;

    if (shallow/* && window["redirectsCounter"] != 1*/) return; // initial load, fired twice because of hydration
    if (window.originalParams) {
      url += url.indexOf('?') != -1 ? `&` : '?'
      url += window.originalParams.substring(1)
    }
    gtag.pageview(url)
  }

  const [routChangeListenerInited, setRoutChangeListenerInited] = useState(false);
  const [seqVal, setSeqVal] = useState("")
  
  function setSeq(val: string) {
    let barbarosSeq = Cookies.get("barbaros_seq");
    if (!barbarosSeq) {
      Cookies.set("barbaros_seq", val)
    }
  }

  useEffect(() => {
    if (routChangeListenerInited) return;
    setRoutChangeListenerInited(true);
    //gtag.pageview(router.asPath)
    router.events.off('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    };
  }, [router.events]);

  return (
    <>
      {!process.env.NEXT_PUBLIC_ANALYTICS_DISABLED && <>
        {/* Global Site Tag (gtag.js) - Google Analytics */}
          <Script
              strategy="afterInteractive"
              src={`https://www.googletagmanager.com/gtag/js?id=${TRACKING_ID}`}
          />
          <Script
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${TRACKING_ID}');
          `,
              }}
          />
      </>}
      <Head>
        <meta name="viewport" content="width=device-width, height=device-height,  initial-scale=1.0, user-scalable=no,user-scalable=0"/>
        <meta content={seqVal} property="seq" />
      </Head>
      {!process.env.NEXT_PUBLIC_ANALYTICS_DISABLED && <noscript>
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5939NK8"
                  height="0" width="0" style={{
            display: 'none',
            visibility: 'hidden'
          }}></iframe>
        </noscript>
      }
      <DataKeeperProvider>
        <SmoothScrollProvider>
          <Component {...pageProps} />
        </SmoothScrollProvider>
      </DataKeeperProvider>
    </>);
}
