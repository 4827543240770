//const GA_TRACKING_ID = "G-M4XEC7X740";
import Cookies from "js-cookie";

const GA_TRACKING_ID = "GTM-5939NK8";

function gtag(...args: any[]){
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

export const pageview = (url: string) => {
  console.log(`EVENT: pageview ${url}`)
  let seq = Cookies.get("barbaros_seq");
  if (!seq) {
    console.warn(`Config without seq!`)
  }

  if (process.env.NEXT_PUBLIC_ANALYTICS_DISABLED) return;
  gtag("config", GA_TRACKING_ID, {
    send_page_view: false,
    user_id: seq,
  });

  gtag('set', { seq });

  window.dataLayer.push({
    'event': 'page_view',
    page_path: url,
    debug_mode: true,
    seq,
  });

  event({
    action: "internal_navigate",
    label: "page_view",
    label2: url
  })
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, label, label2 }: any) => {
  console.log(`EVENT: ${action}`)
  let seq = Cookies.get("barbaros_seq");
  if (!seq) {
    console.warn(`Event without seq!`)
  }

  if (process.env.NEXT_PUBLIC_ANALYTICS_DISABLED) return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': action,
    label,
    label2,
    seq
  });
};