import {createContext, FC, PropsWithChildren, useContext, useEffect, useState} from "react";

/**
 * Transition which will be performed asynchronously with smooth scroll behavior.
 */
export function useSmoothScroll(): ((transition: () => void) => void) {
  let {setSmooth} = useContext(SmoothScrollContext);
  return (transition: () => void) => {
    setSmooth(true);
    setTimeout(() => {
      transition();
      setSmooth(false);
    }, 0)
  }
}

const SmoothScrollContext = createContext({
  smooth: false,
  setSmooth: (s: boolean) => {},
});

export const SmoothScrollProvider: FC<PropsWithChildren> = ({children}) => {
  const [smooth, setSmooth] = useState(false);

  useEffect(() => {
    let classList = document.documentElement.classList;
    smooth ? classList.add('smoothScroll') : classList.remove('smoothScroll');
  }, [smooth])

  return (
    <SmoothScrollContext.Provider value={{
      smooth,
      setSmooth,
    }}>
      <style>{`
        html.smoothScroll {
          scroll-behavior: smooth;
        }
      `}</style>
      {children}
    </SmoothScrollContext.Provider>
  )
}